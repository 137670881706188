.profile {
    margin-bottom: 160px;
}

.profile-content-container:not(.mobile .profile-content-container) {
    display: flex;
    width: 100%;
    column-gap: 32px;
    justify-content: center;
    align-items: center;
}

.profile-content-container:not(.mobile .profile-content-container) .col_25 {
    flex: 25%
}

.profile-content-container:not(.mobile .profile-content-container) .col_50 {
    flex: 50%
}

.profile-content-container:not(.mobile .profile-content-container) .col_75 {
    flex: 75%
}

.profile-content-container .profile-content h1,
.profile-content-container .profile-content p {
    margin: 0;
}

.profile-content-container .profile-content h1 {
    font-size: 4.25rem;
}

.heading.profile-content-title {
    font-family: 'Prompt', sans-serif;
}