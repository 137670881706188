:root {
  --main-background-color-light: #f7f7f7;
  --main-background-color-dark: #1f1f1f;

  --primary-text-color-light: #1f1f1f;
  --primary-text-color-dark: #f7f7f7;

  --header-background-light: #f7f7f7;
  --header-background-dark: #1f1f1f;
  --header-opacity: 0.97;

  --accent-color: #ff9100;

  --section-width-mobile: 90%;
  --section-width-tablet: 80%;
  --section-width-desktop: 60%;
}

html {
  background-color: var(--main-background-color-dark);
  color: var(--primary-text-color-dark);
}

@media (prefers-color-scheme: light) {
  html {
    background-color: var(--main-background-color-light);
    color: var(--primary-text-color-light);
  }
}

body {
  font-family: 'Prompt', sans-serif;
  margin: 0;
}

body::-webkit-scrollbar {
  display: none;
}

.layout {
  max-width: 1028px;
  width: var(--section-width-mobile);
  margin-right: auto;
  margin-left: auto;
}

/** Mobile */
@media (max-width: 767px) {
  .layout {
    width: var(--section-width-mobile);
  }
}

/** Tablet */
@media (min-width: 768px) and (max-width: 1199px) {
  .layout {
    width: var(--section-width-tablet);
  }
}

/** Desktop */
@media (min-width: 1200px) {
  .layout {
    width: var(--section-width-desktop);
  }
}

a:any-link {
  color: var(--primary-text-color-dark);
  text-decoration-line: none;
  position: relative;
  text-decoration: inherit;
}

@media (prefers-color-scheme: light) {
  a:any-link {
    color: var(--primary-text-color-light);
  }
}

a:any-link:not(.displayitem-icon-link, .link-exclude-accent, .icon)::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -10;
  margin: auto;
  height: 16%;
  width: 75%;
  border-radius: 0.125rem;
  background-color: var(--accent-color);
  transition-property: width;
  transition-duration: 0.5s;
}

a:any-link:not(.displayitem-icon-link, .link-exclude-accent, .icon):hover::before {
  width: 100%;
  transition-property: width;
  transition-duration: 0.5s;
}

.main-content {
  padding-top: calc(128px + env(safe-area-inset-top));
  padding-bottom: calc(64px + env(safe-area-inset-bottom));
}