.heading {
    font-family: 'Unbound', sans-serif;
    width: fit-content;
}

.heading.centered {
    text-align: center;
    width: 100%;
}

h2.heading {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 8px;
}

h3.heading {
    font-size: 1.5rem;
    margin-bottom: 8px;
}