section.section.collapsible .heading.section-title::after,
section.section.collapsible_list .heading.section-title::after {
    content: '▼';
    font-size: 0.875rem;
    margin-left: 8px;
}

section.section.collapsible.open .heading.section-title::after,
section.section.collapsible_list.open .heading.section-title::after {
    content: '►';
}

section.section.collapsible .section-content,
section.section.collapsible_list .section-content {
    display: none;
}

section.section.collapsible.open .section-content,
section.section.collapsible_list.open .section-content {
    display: block
}