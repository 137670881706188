.displayitem {
    padding: 8px;
    height: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media (prefers-contrast: more) {
    .displayitem {
        outline: 2px solid var(--primary-text-color-dark);
    }
}

@media (prefers-contrast: more) and (prefers-color-scheme: light) {
    .displayitem {
        outline: 2px solid var(--primary-text-color-light);
    }
}

.displayitem-title {
    display: contents;
    font-size: 1.025rem;
    font-weight: 700;
    margin-bottom: 2px;
}

.displayitem-tagline {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 2px;
    margin-bottom: 2px;
}

.displayitem-icon {
    border-radius: 4px;
}

.displayitem-icon-container {
    max-width: 50px;
    flex: 1;
    align-items: center;
}

.displayitem-icon-link {
    height: 100%;
}

.displayitem-content {
    flex: 1;
}

.displayitem.has-icon .displayitem-content {
    margin-left: 12px;
}