div.grid {
    width: 100%;
    display: grid;
    grid-auto-rows: minmax(10px, auto);
    align-content: baseline;
}

div.grid.col_1 {
    grid-template-columns: 1fr;
}

div.grid.col_2 {
    grid-template-columns: repeat(2, 1fr);
}

div.grid.col_3 {
    grid-template-columns: repeat(3, 1fr);
}

div.grid.col_4 {
    grid-template-columns: repeat(4, 1fr);
}

div.grid.col_1.mobile,
div.grid.col_2.mobile,
div.grid.col_3.mobile,
div.grid.col_4.mobile {
    grid-template-columns: 1fr;
}