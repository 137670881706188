.header {
    background-color: var(--header-background-dark);
    color: var(--primary-text-color);
    margin-bottom: 16px;
    opacity: var(--header-opacity);
}

@media (prefers-color-scheme: light) {
    .header {
        background-color: var(--header-background-light);
    }
}

.header-container {
    background-color: var(--header-background);
    padding: 16px 16px 0 16px;
    border-radius: 16px;
}

.header-title {
    font-size: 1.75rem;
    font-variant: small-caps;
    font-family: 'Unbounded', sans-serif;
    margin: 0;
    display: flex;
}

.header-title a:any-link {
    color: inherit;
    text-decoration: none;
}

nav {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 30;
    margin: auto;
    display: flex;
    width: 100%;
    max-width: 1028px;
    justify-content: flex-end;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

nav .header-navigation {
    margin: auto;
    display: flex;
    width: 100%;
    max-width: 1028px;
    align-items: center;
    gap: 1rem;
}

nav .header-navigation a:first-child {
    margin-left: auto;
}

nav .header-navigation a {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-right: 0.75rem;
}