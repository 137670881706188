.icon {
    text-align: center;
}

.icon .icon-image {
    border-radius: 4px;
    border: 8px solid var(--main-background-color-dark);
}

@media (prefers-color-scheme: light) {
    .icon .icon-image {
        border: 8px solid var(--main-background-color-light);
    }
}